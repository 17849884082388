import {StepsManagerService} from '../../services/checkout/StepsManagerService';
import {BIService} from '../../services/checkout/BIService';
import {CheckoutService} from '../../services/checkout/CheckoutService';
import {CheckoutStep, StepId, StepsManagerStoreProps, StepState} from '../../../types/checkoutApp.types';
import {CheckoutSettingsService} from '../../services/checkout/CheckoutSettingsService';
import {MemberService} from '../../services/checkout/MemberService';
import {FormsService} from '../../services/checkout/FormsService';
import {isShippingFlow as isCurrentFlowShipping} from '../../utils/checkoutFlow.utils';
import {NavigationService} from '../../services/checkout/NavigationService';
import {mapAddressModelToApiAddress, mapContactModelToContactDetails} from '../../utils/billingDetails.utils';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk';
import {hasErrorViolations} from '../../utils/violations.utils';
import {AddressWithContactModel} from '../../models/checkout/AddressWithContact.model';
import {isShippingDestinationSignificant} from '../../utils/isShippingDestinationSignificant';

import {SPECS} from '../../../common/constants';
import {CheckoutModel} from '../../models/checkout/Checkout.model';

export class StepsManagerStore {
  private readonly stepsManagerService: StepsManagerService;
  private readonly biService: BIService;
  private readonly checkoutService: CheckoutService;
  private readonly checkoutSettingsService: CheckoutSettingsService;
  private readonly memberService: MemberService;
  private readonly navigationService: NavigationService;
  private readonly formsService: FormsService;
  private readonly siteStore: SiteStore;
  private readonly updateComponent: () => void;
  private readonly isSSR: boolean;
  private initialStep = 0;
  private hasMovedToNextStep = false;

  constructor({
    stepsManagerService,
    biService,
    checkoutService,
    checkoutSettingsService,
    memberService,
    siteStore,
    updateComponent,
    formsService,
    navigationService,
    isSSR,
  }: {
    stepsManagerService: StepsManagerService;
    biService: BIService;
    checkoutService: CheckoutService;
    checkoutSettingsService: CheckoutSettingsService;
    memberService: MemberService;
    siteStore: SiteStore;
    updateComponent: () => void;
    formsService: FormsService;
    navigationService: NavigationService;
    isSSR: boolean;
  }) {
    this.stepsManagerService = stepsManagerService;
    this.biService = biService;
    this.checkoutService = checkoutService;
    this.checkoutSettingsService = checkoutSettingsService;
    this.memberService = memberService;
    this.siteStore = siteStore;
    this.updateComponent = updateComponent;
    this.formsService = formsService;
    this.navigationService = navigationService;
    this.isSSR = isSSR;
  }

  private readonly sendEditStepClickedBIEvent = (stepId: StepId): void => {
    const previousStepName = this.stepsManagerService.getPreviousStep();
    this.biService.sendEditStepClicked(this.checkoutService.checkout, stepId, previousStepName);
  };

  public initStepsManagerService = async (): Promise<void> => {
    if (this.navigationService.isFastFlow) {
      return;
    }

    this.stepsManagerService.stepsList = this.stepsManagerService.getSteps(this.checkoutService.checkout, {
      isLeanFlow: isEligibleForLeanFlow({
        checkout: this.checkoutService.checkout,
        hasLeanFlowParam: this.navigationService.hasLeanFlowParam,
        isMember: this.memberService.isMember(),
        formsService: this.formsService,
      }),
      shouldShowMemberLoginStep: this.shouldShowMemberLoginStep(),
    });
    const initialStep = await this.calculateInitialStep();
    this.stepsManagerService.updateStepOnStage(initialStep, this.stepsManagerService.stepsList[initialStep], {
      checkout: this.checkoutService.checkout,
    });
  };

  private readonly calculateInitialStep = async (): Promise<number> => {
    const shouldNotSkipWhenFormHasSubscription =
      this.siteStore.experiments.enabled(SPECS.UseNewSubscriptionCheckboxInCustomerDetailsForm) &&
      this.formsService.doesFormHaveSubscription;

    if (!this.memberService.isMember() || shouldNotSkipWhenFormHasSubscription) {
      return this.initialStep;
    }

    const isShippingFlow = isCurrentFlowShipping({
      navigationService: this.navigationService,
      checkoutService: this.checkoutService,
    });
    const checkoutShippingAddress: AddressWithContactModel | undefined = isShippingDestinationSignificant(
      this.checkoutService.checkout.shippingDestination,
      isShippingFlow
    )
      ? this.checkoutService.checkout.shippingDestination
      : undefined;

    const addressWithContact =
      checkoutShippingAddress ??
      this.memberService.getAddressByAddressesServiceId(this.memberService.addressesInfo.defaultAddressId);

    const hasInvalidAddressServiceId = this.memberService.hasInvalidAddressServiceId(
      checkoutShippingAddress?.addressesServiceId
    );

    if (!addressWithContact || hasInvalidAddressServiceId) {
      return this.initialStep;
    }

    const shippingAddressIsValid = await this.formsService.isAddressValidForShipping(addressWithContact, {
      checkoutSettings: this.checkoutSettingsService.checkoutSettings,
      customField: this.checkoutService.checkout.customField,
      isShippingFlow,
      isFastFlow: this.navigationService.isFastFlow,
    });

    if (!shippingAddressIsValid || (checkoutShippingAddress && !this.checkoutService.checkout.buyerInfo.email)) {
      return this.initialStep;
    }

    if (!checkoutShippingAddress) {
      await this.checkoutService.setShippingInfo({
        contactDetails: mapContactModelToContactDetails(addressWithContact.contact),
        email: this.memberService.currentUserEmail,
        addressesServiceId: addressWithContact.addressesServiceId,
        ...(isShippingFlow && {shippingAddress: mapAddressModelToApiAddress(addressWithContact.address)}),
      });
    }

    if (this.checkoutService.updateCheckoutError) {
      return this.initialStep;
    }

    this.initialStep++;

    if (this.stepsManagerService.stepsList[this.initialStep] !== StepId.deliveryMethod) {
      return this.initialStep;
    }

    const isDeliveryMethodValid = !!this.checkoutService.checkout.selectedShippingOption;

    if (!isDeliveryMethodValid) {
      return this.initialStep;
    }

    this.initialStep++;

    return this.initialStep;
  };

  private readonly openStep = (stepIndex: number): void => {
    const stepId = this.stepsManagerService.stepsList[stepIndex];
    this.updateStepOnStage(stepIndex, stepId);
    this.sendEditStepClickedBIEvent(stepId);
  };

  private readonly updateStepOnStage = (stepIndex: number, stepId: StepId): void => {
    this.stepsManagerService.updateStepOnStage(stepIndex, stepId, {checkout: this.checkoutService.checkout});
    this.updateComponent();
    this.hasMovedToNextStep = true;
  };

  private readonly shouldDisplayExpressCheckout = (): boolean => {
    const errorViolationsExist = hasErrorViolations(this.checkoutService.checkout.violations);
    const shouldShowExpressCheckoutSection = this.checkoutSettingsService.showExpressCheckoutSP;
    const shouldShowExpressCheckoutButtonsOnInitialStep = this.siteStore.experiments.enabled(
      SPECS.ShowExpressCheckoutButtonsOnInitialStep
    );
    const activeStep = this.stepsManagerService.getActiveStep().stepIndex;
    const isOnStepThatShowsExpressButtons =
      activeStep === 0 ||
      (shouldShowExpressCheckoutButtonsOnInitialStep && activeStep === this.initialStep && !this.hasMovedToNextStep);

    return (
      !this.isSSR &&
      !this.checkoutService.checkout.hasSubscriptionItems &&
      !this.hasPayLater() &&
      !errorViolationsExist &&
      shouldShowExpressCheckoutSection &&
      isOnStepThatShowsExpressButtons &&
      !this.checkoutService.checkout.isCardTokenizationCheckout
    );
  };

  private readonly hasPayLater = () => {
    return Boolean(this.checkoutService.checkout.payLater?.total?.amount);
  };

  private readonly shouldShowMemberLoginStep = () => {
    return (
      !this.memberService.isMember() &&
      this.memberService.isMemberAreaAppInstalled &&
      this.siteStore.experiments.enabled(SPECS.SupportMemberOnlyCheckout)
    );
  };

  private readonly getCheckoutSteps = (): CheckoutStep[] => {
    const activeStepIndex = this.stepsManagerService.getActiveStep().stepIndex;
    const steps = this.stepsManagerService.stepsList;
    return steps.map((step, index) => {
      let state;
      const isLastStep = index === steps.length - 1;
      if (activeStepIndex < index) {
        state = StepState.EMPTY;
      } else {
        state = activeStepIndex === index || isLastStep ? StepState.OPEN : StepState.COLLAPSED;
      }

      return {
        id: step,
        state,
      };
    });
  };

  /* istanbul ignore next use case does not currently exist but holding on to this capability*/
  private readonly goToNewlyAddedStep = (
    previousSteps: StepId[],
    newSteps: StepId[],
    newIndexOfActiveStep: number
  ): void => {
    const activeStep = this.stepsManagerService.getActiveStep();
    let newStepIndex = newIndexOfActiveStep;
    let newStepId = activeStep.stepId;
    let i = 0;

    while (i < previousSteps.length) {
      if (previousSteps[i] !== newSteps[i]) {
        newStepIndex = i;
        newStepId = newSteps[i];
        break;
      }

      i++;
    }

    this.updateStepOnStage(newStepIndex, newStepId);
  };

  public toProps(): StepsManagerStoreProps {
    const isLeanFlow = isEligibleForLeanFlow({
      checkout: this.checkoutService.checkout,
      hasLeanFlowParam: this.navigationService.hasLeanFlowParam,
      isMember: this.memberService.isMember(),
      formsService: this.formsService,
    });
    const previousSteps = [...this.stepsManagerService.stepsList];
    const newSteps = this.stepsManagerService.getSteps(this.checkoutService.checkout, {
      isLeanFlow,
      shouldShowMemberLoginStep: this.shouldShowMemberLoginStep(),
    });
    this.stepsManagerService.stepsList = newSteps;
    const activeStep = this.stepsManagerService.getActiveStep();
    const newIndexOfActiveStep = newSteps.indexOf(activeStep.stepId);

    /* istanbul ignore next use case does not currently exist but holding on to this capability*/
    if (newIndexOfActiveStep === -1) {
      this.updateStepOnStage(activeStep.stepIndex, newSteps[activeStep.stepIndex]);
    } else if (newIndexOfActiveStep < activeStep.stepIndex) {
      this.updateStepOnStage(newIndexOfActiveStep, activeStep.stepId);
    } else if (newIndexOfActiveStep > activeStep.stepIndex) {
      this.goToNewlyAddedStep(previousSteps, newSteps, newIndexOfActiveStep);
    }

    return {
      isLeanFlow,
      shouldShowMemberLoginStep: this.shouldShowMemberLoginStep(),
      updateStepOnStage: this.updateStepOnStage,
      openStep: this.openStep,
      sendEditStepClickedBIEvent: this.sendEditStepClickedBIEvent,
      shouldDisplayExpressCheckout: this.shouldDisplayExpressCheckout(),
      activeStep: this.stepsManagerService.getActiveStep(),
      stepsList: this.getCheckoutSteps(),
      hasMovedToNextStep: this.hasMovedToNextStep,
    };
  }
}

function isEligibleForLeanFlow({
  checkout,
  hasLeanFlowParam,
  isMember,
  formsService,
}: {
  checkout: CheckoutModel;
  hasLeanFlowParam: boolean;
  isMember: boolean;
  formsService: FormsService;
}): boolean {
  return (
    checkout.payNowTotalAfterGiftCard.amount > 0 &&
    hasLeanFlowParam &&
    !checkout.hasShippableItems &&
    !isMember &&
    !formsService.doesFormHaveExtendedField &&
    !formsService.doesFormHaveAdditionalInfo
  );
}
